
import axios from '@/axios.js'; // 导入你在前面创建的axios实例

export function uploadLogData(name, type, globalVersion) {


    // 使用示例
    const deviceInfo = getDeviceInfo();
    // console.log(deviceInfo);
    // 构造请求数据
    const data = {
        data: {
            event_name: name,
            event_version: globalVersion,
            event_type: type,
            device_type: deviceInfo.device_type,
            device_os: deviceInfo.os,
            device_browser: deviceInfo.browser
        }
    };

    // 设置请求头，包括 Authorization
    const config = {
        headers: {
            Authorization: 'Bearer 28a52a523dceb6d9b9b12fe13f7592532e9d73026a168fbc444a502bc9aa4d3cf0a2851bebd839552e7567133f091beb008cc673f475ce1e980ad86d663e2254a73aafe56e3a22ec365b6d1a38dddc73e174fb957c37c0f1c2b5261fb5b3e72ccd8e56d51b8fce4a38034a4cd61f057f9286d4e7e55a74cd53b2b94fc7769ab7'
        }
    };

    // 发送POST请求
    return axios.post('/trackings', data, config)
        .then(response => {
            console.log('日誌上傳成功:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('日誌上傳失敗:', error);
            throw error;
        });
}


function getDeviceInfo() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let deviceType = 'desktop';
    let os = 'unknown';
    let browser = 'unknown';

    // Detect device type
    if (/android/i.test(userAgent)) {
        deviceType = 'mobile';
        os = 'Android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        deviceType = 'mobile';
        os = 'iOS';
    } else if (/Windows NT/.test(userAgent)) {
        os = 'Windows';
    } else if (/Macintosh/.test(userAgent)) {
        os = 'macOS';
    } else if (/Linux/.test(userAgent)) {
        os = 'Linux';
    }

    // Detect browser
    if (/Chrome/.test(userAgent) && !/Edge/.test(userAgent)) {
        browser = 'Chrome';
    } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
        browser = 'Safari';
    } else if (/Firefox/.test(userAgent)) {
        browser = 'Firefox';
    } else if (/MSIE|Trident/.test(userAgent)) {
        browser = 'Internet Explorer';
    } else if (/Edge/.test(userAgent)) {
        browser = 'Edge';
    }

    return {
        device_type: deviceType,
        os: os,
        browser: browser
    };
}