<template>
  <div>
    <div class="menutitle">
      <h1>Promotion</h1>
    </div>
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(item, index) in imagesArray" :key="index">
        <!-- <img v-lazy="item.image" class="swipe_img" /> -->
        <ProgressiveImage :lowResSrc="item.image_preview" :highResSrc="item.image" :altText="item.name"
          @imageLoaded="handleImageLoaded" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
// import promotionData from "../assets/data/promotionData";

import ProgressiveImage from '@/components/ProgressiveImage.vue';
export default {
  data() {
    return {
      imagesArray: [],
      images: [
        'https://img01.yzcdn.cn/vant/apple-1.jpg',
        'https://img01.yzcdn.cn/vant/apple-2.jpg',
      ],
    };
  },
  components: {
    ProgressiveImage,
  },
  computed: {
    // 计算属性，用于提取所有的图片URL
    // imagesArray() {
    //   return promotionData.map(item => item.image);
    // }
  },
  mounted() {
    // 组件加载完成后执行的逻辑
    // console.log(promotionData);

    this.fetchData()
  },
  methods: {
    // 可以在这里定义组件的方法
    fetchData() {
      this.$axios.get('/promotions') // 替换为你的API端点
        .then(response => {
          this.imagesArray = response.results
          console.log(this.imagesArray);
        })
        .catch(error => {
          console.error('请求失败：', error);
        });
    },
    handleImageLoaded(highResSrc) {
      // 高清图加载完成时执行的操作
      console.log('高清图加载完成:', highResSrc);
      // 这里可以根据需求执行其他操作
    },
  }
};
</script>

<style scoped>
/* 这里的样式仅作用于当前组件 */
h1 {
  color: #333;
  font-size: 24px;
}

.swipe_img {
  width: 100%;
}
</style>
