<template>
  <div id="app">
    <van-nav-bar title="标题" @click-left="onClickLeft" @click-right="onClickRight" fixed placeholder>
      <template #left>
        <van-image width="114" height="38" :src="require('@/assets/img/logo.png')" />
      </template>
      <template #right>
        <van-icon name="wap-nav" size="28" />
      </template>
    </van-nav-bar>

    <van-popup v-model="show" position="right" :style="{ width: '50%', height: '100%' }" closeable>
      <van-list class="list">
        <van-cell v-for="item in list" :key="item" :title="item" @click="toPage(item)" />
      </van-list>
    </van-popup>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
  </div>
</template>

<script>
import { Toast } from 'vant';
// import { Sidebar, SidebarItem } from 'vant';
export default {
  data() {
    return {
      show: false,
      activeKey: 0,
      list: [
        "Shoping",
        "Dining",
        "Facilities",
        "Transportation",
        "Promotion",
      ]
    };
  },
  mounted() {
    // 组件加载完成后执行的逻辑
  },
  methods: {
    onClickLeft() {
      this.$router.push({ path: '/' });
    },
    onClickRight() {
      this.show = true
    },
    toPage(page) {
      console.log(page);
      let targetPath = '';

      switch (page) {
        case "Shoping":
          targetPath = '/';
          break;

        case "Dining":
          targetPath = '/dinings';
          break;
        case "Facilities":
          targetPath = '/facilities';
          break;
        case "Transportation":
          targetPath = '/transportation';
          break;
        case "Promotion":
          targetPath = '/promotion';
          break;

        default:
          Toast('COMING SOON');
          this.show = false;
          return; // 退出方法，不执行后续代码
      }

      // 检查当前路径是否与目标路径相同
      if (this.$route.path !== targetPath) {
        this.$router.push({ path: targetPath });
      } else {
        console.log('Already on the target page:', targetPath);
      }

      this.show = false;
    },
  }
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  background-color: #f7f8fa;
  width: 100vw;
  // height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.list {
  margin-top: 8vh;
}

.menutitle {
  text-align: center;
}

h1 {
  color: #333;
  font-size: 24px;
}
</style>
