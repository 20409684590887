<template>
  <div class="main">
    <div class="menutitle">
      <h1>{{ routeType === "1" ? "Shopping" : "Dining" }}</h1>
    </div>
    <div class="map_box">
      <img class="map" src="../assets/img/map.jpg" alt="" srcset="">

      <img id="urhere" class="positon_icon" src="../assets/img/you_are_here.png" alt="urhere" />
      <img id="icon_end" class="positon_icon" src="../assets/img/icon_end.png" alt="end" />
    </div>
    <van-button round type="info" icon="arrow-left" id="back_icon" @click="goBack">Back</van-button>
    <div class="shopdetail" v-if="routeType === '1' || routeType === '2'">
      <van-card tag="NewShop" :desc="selectedShop.desc_tc" :title="selectedShop.name" :thumb="selectedShop.image">
        <!-- <template #thumb>
          <van-image width="100" height="100" :src="selectedShop.photo" />
        </template> -->
        <template #title>
          <p class="title">{{ selectedShop.name }}</p>
        </template>
        <template #desc>
          <p class="desc">Num:{{ selectedShop.num }}</p>
          <p class="desc">Phone:{{ selectedShop.phone }}</p>
          <p class="desc">{{ selectedShop.desc_tc }}</p>
        </template>
      </van-card>
    </div>
  </div>
</template>

<script>
// import MapFile from '@/components/MapFile.vue'
// import shopData3 from "../assets/data/shopData3";
export default {
  data() {
    return {
      selectedShop: [],
      pointf1: [
        [98, 20], [100, 36], [109, 35], [108, 18], [108, 57]
      ],
      pointf2: [
        [64, 23], [64, 53], [49, 53], [51, 30], [58, 30]
      ],
      pointf3: [
        [15, 57], [4, 19], [15, 20], [7, 37], [18, 37]
      ],
    };
  },
  computed: {
    queryId() {
      return this.$route.query.id;
    },
    routeType() {
      return this.$route.query.type;
    },
  },
  // components: {
  //   MapFile
  // },
  mounted() {

    window.moveIconStart = this.moveIconStart;
    // console.log(this.queryId);
    // this.getShopById(this.queryId)
    this.fetchData()

  },
  methods: {
    // 可以在这里定义组件的方法

    fetchData() {
      let basePath = ""
      if (this.routeType === "1") {
        basePath = "shops"
      }else if (this.routeType === "2") {
        basePath = "dinings"
      }
      this.$axios.get(`/${basePath}/` + this.queryId) // 替换为你的API端点
        .then(response => {
          this.selectedShop = response.results
          // console.log(this.selectedShop);
          // console.log(this.selectedShop.image);
          this.getShopById()
        })
        .catch(error => {
          console.error('请求失败：', error);
        });
    },
    getShopById() {
      this.processNumbers(this.selectedShop.floor, this.selectedShop.num)
    },
    goBack() {
      // this.$router.push({ path: '/' });
      this.$router.go(-1);
    },
    // 移动起点的方法
    moveIconStart(x, y) {
      // 获取img元素
      const imgElement = document.getElementById('urhere');
      if (imgElement) {
        // 设置img元素的定位样式
        imgElement.style.left = `${x}vw`;
        imgElement.style.top = `${y}vw`;
      }
    },

    // 移动终点的方法
    moveIconEnd(x, y) {
      // 获取img元素
      const imgElement = document.getElementById('icon_end');
      if (imgElement) {
        // 设置img元素的定位样式
        imgElement.style.left = `${y}vw`;
        imgElement.style.top = `${x}vw`;
      }
    },

    // 根据floor和num
    processNumbers(floor, num) {
      let pointArr = this.pointf1
      // 提取 floor 中的每个数字
      const floorDigits = (floor + '').match(/\d/g) || []; // 匹配所有数字字符
      if (floorDigits) {
        // 遍历 floor 中的每个数字，并进行判断和处理
        floorDigits.forEach(digit => {
          switch (parseInt(digit, 10)) { // 將字符轉換為整數
            case 0:
              console.log('0 表示什麽特定處理');
              break;
            case 1:
              pointArr = this.pointf1
              console.log('1 表示什麽特定處理');
              break;
            case 2:
              pointArr = this.pointf2
              console.log('2 表示什麽特定處理');
              break;
            case 3:
              pointArr = this.pointf3
              console.log('3 表示什麽特定處理');
              break;
            // 根據需要添加更多的case
            default:


              console.log(`${digit} 表示默認處理`);
          }
        });
      }

      console.log(pointArr);

      // 获取 num 中的最后一个数字字符，并转换为整数
      const lastNumDigit = parseInt((num + '').charAt(num.toString().length - 1), 10);
      // 提取 num 中的数字，并除以2后向下取整得到一个整数
      const numValue = parseInt(lastNumDigit, 10); // 转换为整数
      const roundedNum = Math.floor(numValue / 2); // 除以2并向下取整

      console.log(pointArr[roundedNum]);
      // 执行地图换终点的方法
      this.moveIconEnd(pointArr[roundedNum][0], pointArr[roundedNum][1])

    }

  }
};
</script>

<style scoped lang="less">
h1 {
  color: #333;
  font-size: 24px;
}

#back_icon {
  position: fixed;
  top: 9vh;
  left: 2vw;
}

.shopdetail {
  position: fixed;
  width: 100%;
  bottom: 0;
  // margin-top: -60vh;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3vw;
}

.title {
  font-size: 0.9rem;
  font-weight: bold;
}

.desc {
  text-align: left;
}

.map_box {
  position: relative;
  height: 100vh;
  padding-bottom: 30vh;

  >.map {
    width: 100%;
  }

  >.positon_icon {
    position: absolute;
    top: 63vw;
    left: 59vw;
    width: 20%;

    &#icon_end {
      animation: blink-animation 1.6s infinite;

      top: 12vw;
      left: 22vw;
    }
  }
}


/* 闪烁动画 */
@keyframes blink-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
