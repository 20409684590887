<template>
    <div class="image-wrapper">
        <!-- 模糊图片 -->
        <img :src="lowResSrc" :alt="altText" class="blurred-image" @load="onLowResLoad" />
        <!-- 高清图片 -->
        <img :src="highResSrc" :alt="altText" class="high-res-image" :class="{ 'loaded': highResLoaded }"
            @load="onHighResLoad" />
    </div>
</template>

<script>
export default {
    props: {
        lowResSrc: {
            type: String,
            required: true,
        },
        highResSrc: {
            type: String,
            required: true,
        },
        altText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            highResLoaded: false,
        };
    },
    methods: {
        onLowResLoad() {
            // 低分辨率图片加载完成后的处理逻辑（可选）
        },
        onHighResLoad() {
            // 当高清图加载完成时，将 highResLoaded 设置为 true
            this.highResLoaded = true;
            // 向父组件发出事件通知，传递图片加载完成的信息
            this.$emit('imageLoaded', this.highResSrc);
        },
    },
};
</script>

<style scoped>
.image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 177.78vw;
    /* 设置比例为16:9，如果是其他比例可自行调整 */
    overflow: hidden;
}

.image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* 保持图片比例并裁剪溢出的部分 */
    transition: opacity 0.5s ease;
}

.blurred-image {
    filter: blur(2px);
    /* 模糊处理，数值可根据需求调整 */
    opacity: 1;
    /* 确保模糊图最开始是显示的 */
}

.high-res-image {
    opacity: 0;
    /* 初始状态下高清图是透明的 */
}

.high-res-image.loaded {
    opacity: 1;
    /* 高清图加载完成后显示 */
}
</style>