import Vue from 'vue'
import App from './App.vue'
import axios from '@/axios.js'; // 导入你在前面创建的axios实例
import router from './router'
import Vant from 'vant';
// import 'vant/lib/index.css';
import './assets/styles/global.less';
import { Lazyload } from 'vant';


// 定义全局变量
Vue.prototype.$globalVersion = 'v1.0';

Vue.use(Vant);
Vue.use(Lazyload);

// 将axios挂载到Vue原型上
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
