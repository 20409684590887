import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '../views/MainView.vue'
import DiningView from '../views/DiningView.vue'
import ShopDetail from '../views/ShopDetail.vue'
import TransportationView from '../views/TransportationView.vue'
import PromotionView from '../views/PromotionView.vue'
import FacilitiesView from '../views/FacilitiesView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainView
  },
  {
    path: '/dinings',
    name: 'Dinings',
    component: DiningView
  },
  {
    path: '/facilities',
    name: 'Facilities',
    component: FacilitiesView
  },
  {
    path: '/shopdetail',
    name: 'ShopDetail',
    component: ShopDetail
  },
  
  {
    path: '/transportation',
    name: 'Transportation',
    component: TransportationView
  },
  {
    path: '/promotion',
    name: 'Promotion',
    component: PromotionView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
