<template>
  <div class="main">
    <div class="menutitle">
      <h1>Shopping</h1>
    </div>
    <van-grid :border="false" :column-num="3" :gutter="10">
      <van-grid-item class="list_item" v-for="(item, index) in shopData" :key="item.id"
        @click="toDetail(item.id, item.name)">
        <van-image class="list_item_image" :src="item.image" v-lazy="item.image" />
        <p :class="['list_item_text', { marquee: shouldMarquee[index] }]" ref="text">{{ item.name }}</p>
      </van-grid-item>
    </van-grid>
  </div>
</template>


<script>
// import shopData3 from "../assets/data/shopData3";
import { uploadLogData } from '../components/utilsTracking';

export default {
  data() {
    return {
      shopData: "",
      shouldMarquee: [],
      selectedTypes: [206, 207, 214, 212, 210, 204, 200, 213, 198, 216, 215, 209, 202, 201, 205, 203] // 假设我们要筛选 type 为 207 和 204 的商店数据
    };
  },
  computed: {
  },
  mounted() {
    this.checkMarquee();
    window.addEventListener('resize', this.checkMarquee);
    this.fetchData()

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMarquee);
  },
  methods: {
    fetchData() {
      this.$axios.get('/shops') // 替换为你的API端点
        .then(response => {
          this.shopData = response.data
        })
        .catch(error => {
          console.error('请求失败：', error);
        });
    },
    toDetail(id, name) {
      uploadLogData(name, "shopView", this.$globalVersion)
      this.$router.push({ path: '/shopdetail', query: { id: id, type: "1" } });
    },
    checkMarquee() {
      this.$nextTick(() => {
        const textElements = this.$refs.text;
        if (textElements && textElements.length) {
          textElements.forEach((text, index) => {
            const wrapperWidth = text.parentElement.offsetWidth;
            const contentWidth = text.scrollWidth;
            this.$set(this.shouldMarquee, index, contentWidth > wrapperWidth);
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
h1 {
  color: #333;
  font-size: 24px;
}

p {
  color: #666;
  font-size: 16px;
}

.list_item {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
}

.list_item_image {
  margin-top: -30px;
  // border: 0.5px solid rgba(0,0, 0, 0.3);

  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.list_item_text {
  position: absolute;
  bottom: 20px;
  margin-bottom: 0;
  display: inline-block;
  transition: transform 0.5s linear;
}

.marquee {
  padding-left: 100%;
  animation: marquee 5s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
