<template>
  <div class="main">
    <div class="menutitle">
      <h1>Facilities</h1>
    </div>
    <van-grid :border="false" :column-num="3" :gutter="10">
      <van-grid-item class="list_item" v-for="(item, index) in facilitiesData" :key="item.id"
        @click="toDetail(item.id, item.name)">
        <van-image class="list_item_image" :src="item.image" v-lazy="item.image" />
        <p :class="['list_item_text', { marquee: shouldMarquee[index] }]" ref="text">{{ item.name }}</p>
      </van-grid-item>
    </van-grid>
  </div>
</template>


<script>
import facilitiesData3 from "../assets/data/facilityData";
import { uploadLogData } from '../components/utilsTracking';

export default {
  data() {
    return {
      facilitiesData: facilitiesData3,
      shouldMarquee: [],
    };
  },
  computed: {

  },
  mounted() {
    console.log(this.facilitiesData);
    this.checkMarquee();
    window.addEventListener('resize', this.checkMarquee);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMarquee);
  },
  methods: {
    toDetail(id, name) {
      uploadLogData(name,"facilitiesView",this.$globalVersion)
      this.$router.push({ path: '/shopdetail', query: { id: id, type: "3" } });
    },

    // uploadLogData(name) {
    //   // 构造请求数据
    //   const data = {
    //     data: {
    //       event_name: name,
    //       event_version: this.$globalVersion,
    //       event_type: "facilitiesView"
    //     }
    //   };

    //   // 设置请求头，包括 Authorization
    //   const config = {
    //     headers: {
    //       Authorization: 'Bearer 28a52a523dceb6d9b9b12fe13f7592532e9d73026a168fbc444a502bc9aa4d3cf0a2851bebd839552e7567133f091beb008cc673f475ce1e980ad86d663e2254a73aafe56e3a22ec365b6d1a38dddc73e174fb957c37c0f1c2b5261fb5b3e72ccd8e56d51b8fce4a38034a4cd61f057f9286d4e7e55a74cd53b2b94fc7769ab7'
    //     }
    //   };

    //   // 发送POST请求
    //   this.$axios.post('/trackings', data, config)
    //     .then(response => {
    //       console.log('日志上传成功:', response.data);
    //     })
    //     .catch(error => {
    //       console.error('日志上传失败:', error);
    //     });
    // },


    checkMarquee() {
      this.$nextTick(() => {
        const textElements = this.$refs.text;
        if (textElements && textElements.length) {
          textElements.forEach((text, index) => {
            const wrapperWidth = text.parentElement.offsetWidth;
            const contentWidth = text.scrollWidth;
            this.$set(this.shouldMarquee, index, contentWidth > wrapperWidth);
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
h1 {
  color: #333;
  font-size: 24px;
}

p {
  color: #666;
  font-size: 16px;
}

.list_item {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
}

.list_item_image {
  margin-top: -30px;
  // border: 0.5px solid rgba(0,0, 0, 0.3);

  // box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.list_item_text {
  position: absolute;
  bottom: 20px;
  margin-bottom: 0;
  display: inline-block;
  transition: transform 0.5s linear;
}

.marquee {
  padding-left: 100%;
  animation: marquee 5s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
