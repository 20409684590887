<template>
  <div>
    <div class="menutitle">
      <h1>Transportation</h1>
    </div>
    <div class="img_box">
      <img src="../assets/img/trans2.jpg" alt="" @click="openDialog($event, `2`)" />
      <img src="../assets/img/trans1.jpg" alt="" @click="openDialog($event, `1`)" />
    </div>

    <!-- Dialog 控制部分 -->
    <div v-if="dialogVisible" class="dialog">
      <img v-if="currentImage ==='2'" src="../assets/img/trans2.jpg" alt="Preview" class="dialog-image" />
      <img v-if="currentImage ==='1'" src="../assets/img/trans1.jpg" alt="Preview" class="dialog-image" />
      <button class="close-button" @click="closeDialog">
        <img src="../assets/img/close.png" alt="" srcset="">
      </button>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {

      dialogVisible: false, // 控制 Dialog 是否可见
    };
  },
  mounted() {
    // 组件加载完成后执行的逻辑
  },
  methods: {
    // 可以在这里定义组件的方法
    openDialog(event, imageSrc) {
      event.stopPropagation(); // 防止事件传播
      this.dialogVisible = true;
      this.currentImage = imageSrc;
    },

    closeDialog() {
      this.dialogVisible = false;
      this.currentImage = '';
    },
  }
};
</script>

<style scoped lang="less">
/* 这里的样式仅作用于当前组件 */
h1 {
  color: #333;
  font-size: 24px;
}

p {
  color: #666;
  font-size: 16px;
}

.img_box {
  >img {
    width: 100%;
  }
}


/* Dialog 样式 */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  /* 半透明黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-image {
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  position: absolute;
  top: 4vw;
  right: 4vw;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  >img{
    width: 56px;
  }
}
</style>
